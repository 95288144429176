<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div id="input-date-combo" class="input-date-combo fadeIn-2">
        <select
          v-model="day"
          class="input-day input-standard-white validation"
          placeholder="Day"
          required
          @keypress.enter.prevent="forward"
        >
          <option :value="null" disabled>Day</option>
          <option
            v-for="(value, index) in dayOptions"
            :key="index"
            :value="value"
          >
            {{ value }}
          </option>
        </select>
        <select
          v-model="month"
          class="input-month input-standard-white validation"
          required
          @keypress.enter.prevent="forward"
        >
          <option :value="null" disabled>Month</option>
          <option
            v-for="(value, index) in monthOptions"
            :key="index"
            :value="value.value"
          >
            {{ value.label }}
          </option>
        </select>
        <select
          v-model="year"
          class="input-year input-standard-white validation"
          required
          @keypress.enter.prevent="forward"
        >
          <option :value="null" disabled>Year</option>
          <option
            v-for="(value, index) in yearOptions"
            :key="index"
            :value="value"
          >
            {{ value }}
          </option>
        </select>
      </div>
      <!--
      <div class="input-container width600 fadeIn-2">

        <span
          v-if="dob"
          class="fakelabel"
        >Date of Birth</span>
        <Datepicker
          v-model="dob"
          :input-class="{ 'input-standard-grey validation': true, 'fake-required': !dob}"
          placeholder="Date of birth"
          id="dob"
          v-bind:required="true"
          :disabled-dates="disabledDates"
          :open-date="openDate"
          initial-view="year"
          name="dob"
          @selected="save"
                    calendar-class=""
        ></Datepicker>
      </div>
      -->
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
// import Datepicker from 'vuejs-datepicker'
var moment = require('moment')

export default {
  name: 'ProfileDob',
  components: {
    NavQuestion,
    NavQuestionButtons
    // Datepicker
  },
  mounted() {
    this.$emit('progress', '20%')
    var yearNow = new Date().getFullYear() - 17
    for (var i = 0; i < 101; i++) {
      this.yearOptions.push(yearNow)
      yearNow = yearNow - 1
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    isValidated() {
      if (this.day && this.month && this.year) return true
      return false
    },
    number: () => '2',
    start() {
      return this.$router.history.current.name === 'WriteMyWillDob'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    basket() {
      return this.$store.getters.checkoutBasket
    },
    heading: () => 'When were you born?',
    subHeading: () =>
      'Please enter your date of birth for example 14 March 1980. You must be over 18 to make a Will.',
    forwardTo() {
      if (this.start) return '/write_my_will/gender'
      return '/profile/gender'
    },
    backTo() {
      if (this.start) return '/write_my_will/name'
      return '/profile/name'
    }
  },
  watch: {
    personalDetails: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.dob_year && value.dob_month && value.dob_day) {
          this.day = value.dob_day
          this.month = value.dob_month
          this.year = value.dob_year
        }
      }
    }
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      dob: null,
      dayOptions: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31
      ],
      monthOptions: [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
      ],
      yearOptions: [],
      disabledDates: {
        from: moment().subtract(18, 'years').toDate()
      },
      openDate: moment().subtract(40, 'years').toDate()
    }
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          dob_day: this.day,
          dob_month: this.month,
          dob_year: this.year
        })
      }
    },
    forward(event) {
      event.target.blur()
    }
  }
}
</script>

<style scoped></style>
